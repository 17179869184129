import "./App.css";
import { Fragment, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./pages/client/home/Home";
import { Dashboard } from "./pages/admin/dashboard/Dashboard";
import { AdminLayout } from "./layout/admin/layout/AdminLayout";
import { Company } from "./pages/admin/company/Company";
import { Category } from "./pages/admin/category/Category";
import { CategoryEdit } from "./pages/admin/category/CategoryEdit";
import { Product } from "./pages/admin/product/Product";
import { ProductVariant } from "./pages/admin/product/ProductVariant";
import { ProductVariantEdit } from "./pages/admin/product/ProductVariantEdit";
import { Layout } from "./layout/client/layout/Layout";
import { IzelmanCcSales } from "./pages/admin/izelman/IzelmanCcSales";
import { GrandPlazaCcSales } from "./pages/admin/grandPlaza/GrandPlazaCcSales";
import { Sss } from "./pages/client/sss/Sss";
import { BasvuruSorgula } from "./pages/client/basvuruSorgula/BasvuruSorgula";
import { Iletisim } from "./pages/client/iletisim/Iletisim";
import { WebApply } from "./pages/client/webApply/WebApply";
import { Support } from "./pages/admin/support/Support";
import { WebApplyAdmin } from "./pages/admin/webApply/WebApplyAdmin";
import { Stuff } from "./pages/client/stuff/Stuff";
import { Appointment } from "./pages/client/appointment/Appointment";
import { Faq } from "./pages/admin/faq/Faq";
import { HomePage } from "./pages/admin/homePage/HomePage";
import { ProductClient } from "./pages/client/productClient/ProductClient";
import { ProductDetail } from "./pages/client/productDetail/ProductDetail";
import { Vakif } from "./pages/client/vakif/Vakif";
import { Izsu } from "./pages/client/izsu/Izsu";
import { IzsuAdmin, IzsuApply } from "./pages/admin/izsuApply/IzsuApply";
import { GetSupport } from "./pages/client/getSupport/GetSupport";
import { GiveSupport } from "./pages/client/giveSupport/GiveSupport";
import {
  OrderAdmin,
  OrderProduct,
  ProductSale,
} from "./pages/admin/orderProduct/OrderProduct";
import { IzsuPaidInvoice } from "./pages/admin/izsuPaidInvoice/IzsuPaidInvoice";
import { OurServices } from "./pages/client/ourServices/OurServices";
import { OurServicesAdmin } from "./pages/admin/ourServicesAdmin/OurServicesAdmin";
import { AnnouncementAdmin } from "./pages/admin/announcement/AnnouncementAdmin";
import { AnnouncementDetail } from "./pages/client/announcement/AnnouncementDetail";
import { AppointmentMain } from "./pages/client/appointmentMain/AppointmentMain";
import { AppointmentApply } from "./pages/client/appointmentApply/AppointmentApply";
import { SutKuzusu } from "./pages/client/sutKuzusu/SutKuzusu";

import { ChangePassword } from "./pages/admin/changePassword/ChangePassword";
import { ScrollTop } from "./util/commonUtil";

function App() {
  const location = useLocation();

  useEffect(() => {
    ScrollTop();
  }, [location.pathname]);

  return (
    <Fragment>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/getSupport" element={<GetSupport />} />
          <Route path="/destekVer" element={<GiveSupport />} />
          <Route path="/randevuHizmetleri" element={<AppointmentMain />} />
          <Route
            path="/randevuHizmetleri/Basvur"
            element={<AppointmentApply />}
          />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/webApply" element={<WebApply />} />
          <Route path="/sutKuzusu" element={<SutKuzusu />} />
          <Route path="/stuff" element={<Stuff />} />
          <Route path="/basvuruSorgula" element={<BasvuruSorgula />} />
          <Route path="/sss" element={<Sss />} />
          <Route path="/iletisim" element={<Iletisim />} />
          <Route path="/productClient" element={<ProductClient />} />
          <Route path="/productDetail" element={<ProductDetail />} />
          <Route path="/vakif" element={<Vakif />} />
          <Route path="/izsu" element={<Izsu />} />
          <Route path="/hizmetlerimiz" element={<OurServices />} />
          <Route path="/duyuru" element={<AnnouncementDetail />} />
        </Route>
        <Route exact path="/admin" element={<AdminLayout />}>
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/changePassword" element={<ChangePassword />} />
          <Route path="/admin/homePage" element={<HomePage />} />
          <Route path="/admin/company" element={<Company />} />
          <Route path="/admin/faq" element={<Faq />} />
          <Route path="/admin/announcement" element={<AnnouncementAdmin />} />
          <Route path="/admin/ourServices" element={<OurServicesAdmin />} />
          <Route path="/admin/support" element={<Support />} />
          <Route path="/admin/webApplyAdmin" element={<WebApplyAdmin />} />
          <Route path="/admin/category" element={<Category />} />
          <Route path="/admin/category/edit" element={<CategoryEdit />} />
          <Route path="/admin/product" element={<Product />} />
          <Route path="/admin/product/variant" element={<ProductVariant />} />
          <Route path="/admin/orderProduct" element={<OrderProduct />} />
          <Route path="/admin/izsu/izsuApply" element={<IzsuApply />} />
          <Route
            path="/admin/izsu/izsuPaidInvoice"
            element={<IzsuPaidInvoice />}
          />

          <Route
            path="/admin/reports/izelmanCcSales"
            element={<IzelmanCcSales />}
          />
          <Route
            path="/admin/reports/grandPlazaCcSales"
            element={<GrandPlazaCcSales />}
          />

          <Route
            path="/admin/product/variant/edit"
            element={<ProductVariantEdit />}
          />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
