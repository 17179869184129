import moment from "moment";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";

export const RgbaConvert = (value, opacity) => {
  const red = parseInt(value.substring(1, 3), 16);
  const green = parseInt(value.substring(3, 5), 16);
  const blue = parseInt(value.substring(5, 7), 16);

  const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return rgba;
};

export const ConvertFileBase64 = (file) => {
  return new Promise((resolve, rejest) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
      // const base64 = reader.result;
      const preview = e.target.result;
      resolve({ base64, preview });
    };
  });
};

export const GetFileExtension = (filename) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
};

export const ScrollTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const FormatDateWithClock = (date) => {
  return moment(date).format("DD/MM/YYYY : HH:mm");
};

export const ConvertCharset = (value) => {
  const locales = ["tr", "TR", "tr-TR", "tr-u-co-search", "tr-x-turkish"];
  value = value
    .replaceAll("İ", "i")
    .toLocaleLowerCase(locales)
    .replaceAll("ç", "c")
    .replaceAll("ğ", "g")
    .replaceAll("ü", "u")
    .replaceAll("ı", "i")
    .replaceAll("ö", "o")
    .replaceAll("ş", "s");
  return value;
};

export const ConvertCurrency = (value) => {
  if (value === undefined || value.length === 0) return null;
  value = value.toLocaleString("tr-TR", { style: "currency", currency: "TRY" });
  return value;
};

export const DownloadExcel = (dataSource, sheetName, fileName) => {
  const ws = XLSX.utils.json_to_sheet(dataSource);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFileXLSX(
    wb,
    `${fileName}-${Math.floor(Math.random() * 10000)}.xlsx`,
  );
  return true;
};

export const DataTableProcess = (data, DeleteHandler, EditHandler, linkUrl) => {
  return (
    <div className="dataTableProcess">
      {linkUrl ? (
        <Link
          className="dataTableProcessEdit"
          style={{ paddingBottom: "6px" }}
          to={linkUrl}
          state={data}
        >
          Düzenle
        </Link>
      ) : (
        <button
          className="dataTableProcessEdit"
          onClick={(data) => EditHandler(data)}
        >
          Düzenle
        </button>
      )}
      {DeleteHandler && (
        <button
          className="dataTableProcessDelete"
          id={data.id}
          onClick={(event) => DeleteHandler(event)}
        >
          Sil
        </button>
      )}
    </div>
  );
};

export const DataTableImage = (imgUrl) => {
  return (
    <img
      src={imgUrl}
      alt=""
      style={{ width: "70px", objectFit: "contain", padding: ".5rem" }}
    />
  );
};

export const EntityStatusHtml = (status) => {
  switch (status) {
    case 0:
      return <span className="dataStatusActive">Aktif</span>;
    case 1:
      return <span className="dataStatusPassive">Pasif</span>;
    case 2:
      return <span className="dataStatusPassive">Silinmis</span>;
    case 3:
      return (
        <span className="dataStatusOrange">Sadece Başvuru Yapılabilir</span>
      );
    case 4:
      return (
        <span className="dataStatusInfo">Sadece Sorgulama Yapılabilir</span>
      );
    default:
      break;
  }
};

export const EntityStatusData = () => {
  const dataStatus = [
    {
      value: 0,
      text: "Aktif",
    },
    {
      value: 1,
      text: "Pasif",
    },
    // {
    //   value: 2,
    //   text: "Sil",
    // },
    // {
    //   value: 3,
    //   text: "Sadece Başvuru Yapılabilir",
    // },
    // {
    //   value: 4,
    //   text: "Sadece Sorgulama Yapılabilir",
    // },
  ];
  return dataStatus;
};

export const IzsuStatusHtml = (status) => {
  switch (status) {
    case 0:
      return <span className="dataStatusOrange">Başvuru Kontrol</span>;
    case 1:
      return <span className="dataStatusOrange">Başvuru Alındı</span>;
    case 2:
      return <span className="dataStatusActive">Onaylandı</span>;
    case 3:
      return <span className="dataStatusPassive">Reddedildi</span>;
    default:
      break;
  }
};

export const SupportTypeHtml = (status) => {
  switch (status) {
    case 0:
      return <span>Web Başvuru</span>;
    case 1:
      return <span>Randevu</span>;
    case 2:
      return <span>Eşya</span>;
    // case 3:
    //   return <span>İzsu Fatura</span>;
    case 4:
      return <span>Ürün</span>;
    case 5:
      return <span>Link</span>;
    default:
      break;
  }
};

export const SupportTypeData = () => {
  const data = [
    {
      value: 0,
      text: "Web Başvuru",
    },
    {
      value: 1,
      text: "Randevu",
    },
    {
      value: 2,
      text: "Eşya",
    },
    // {
    //   value: 3,
    //   text: "İzsu Fatura",
    // },
    {
      value: 4,
      text: "Ürün",
    },
    {
      value: 5,
      text: "Link",
    },
  ];
  return data;
};

export const ProductPaymentTypeData = () => {
  const data = [
    {
      value: 0,
      text: "Kredi Karti",
    },
    {
      value: 1,
      text: "Iban",
    },
    {
      value: 2,
      text: "Kredi Karti & Iban",
    },
  ];
  return data;
};

export const ProductPaymentTypeHtml = (value) => {
  switch (value) {
    case 0:
      return "Kredi Karti";
    case 1:
      return "Iban";
    case 2:
      return "Kredi Karti & Iban";
    default:
      break;
  }
};

export const LangData = () => {
  const lang = [
    {
      value: 0,
      text: "Tr",
    },
    {
      value: 1,
      text: "En",
    },
  ];
  return lang;
};

export const LangHtml = (lang) => {
  switch (lang) {
    case 0:
      return "Tr";
    case 1:
      return "En";
    default:
      break;
  }
};

export const ProductTypeData = () => {
  const lang = [
    {
      value: 0,
      text: "Ürün",
    },
    {
      value: 1,
      text: "Bilet",
    },
    // {
    //   value: 2,
    //   text: "Dijital Ürün",
    // },
    // {
    //   value: 3,
    //   text: "Link (Biletix vb.)",
    // },
  ];
  return lang;
};

export const ProductTypeHtml = (lang) => {
  switch (lang) {
    case 0:
      return "Ürün";
    case 1:
      return "Bilet";
    // case 2:
    //   return "Dijital Ürün";
    // case 3:
    //   return "Link (Biletix vb.)";
    default:
      break;
  }
};

export const ProductQuantityTypeData = () => {
  const lang = [
    {
      value: 0,
      text: "Limitli (Stoklu)",
    },
    {
      value: 1,
      text: "Limitsiz (Stoksuz)",
    },
  ];
  return lang;
};

export const ProductQuantityTypeHtml = (lang) => {
  switch (lang) {
    case 0:
      return "Limitli (Stoklu)";
    case 1:
      return "Limitsiz (Stoksuz)";
    default:
      break;
  }
};

export const OrderStatusHtml = (status) => {
  switch (status) {
    case 0:
      return <span className="dataStatusInfo">Oluşturuldu</span>;
    case 1:
      return <span className="dataStatusActive">Başarılı</span>;
    case 2:
      return <span className="dataStatusPassive">İptal Edildi</span>;
    case 3:
      return <span className="dataStatusOrange">İade Edildi"</span>;
    case 4:
      return <span className="dataStatusPassive">Ödeme Hata</span>;
    case 5:
      return <span className="dataStatusInfo">Iban Ödeme Onayı Bekliyor</span>;

    default:
      break;
  }
};
