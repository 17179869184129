import React, { useContext, useState } from "react";
import { Fragment } from "react";
import classes from "./BasvuruYapForm.module.css";
import { Input } from "../../../components/ui/input/Input";
import { AppContext } from "../../../context/app-context";
import { useLocation, useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import { KVKK, RizaMetni } from "../../../util/commonText";
import {
  CheckFormValid,
  FixDate,
  Validation,
} from "../../../components/ui/input/Validation";
import { SwalErrorMessage } from "../../../util/swalUtil";

export const BasvuruYapForm = ({
  formInput,
  setFormInput,
  supportDataOpt,
  supportData,
}) => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const [formInputIsValid, setFormInputIsValid] = useState({
    basvuruId: { status: false, message: "Başvuru: Zorunludur." },
    tcKimlikNo: { status: false, message: "T.C. Kimlik: Zorunludur." },
    dogumTarihi: { status: false, message: "Doğum Tarihi: Zorunludur." },
    cepTelefonu: { status: false, message: "Telefon Numarası: Zorunludur." },
    kvkk: { status: false, message: "KVKK: Onayı zorunludur." },
    rizaMetni: { status: false, message: "Rıza Metni: Onayı zorunludur." },
  });
  const [smsVerification, setSmsVerification] = useState(false);

  const SubmitHandler = () => {
    const isValid = CheckFormValid(formInputIsValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }

    const support = Array.from(supportData).filter(
      (x) => x.id == formInput.basvuruId,
    )[0];

    setFormInput((prev) => ({
      ...prev,
      id: formInput.basvuruId,
    }));
    navigate(support.url, { state: { state: support, formInput: formInput } });
  };

  return (
    <Fragment>
      <form
        id="form"
        className={classes.form}
        onSubmit={(event) => {
          event.preventDefault();
          SubmitHandler();
        }}
      >
        <Input
          type="select"
          style={{
            width: "100%",
            color: "#1B437E",
          }}
          label="Başvuru"
          option={supportDataOpt}
          validation={
            formInput.basvuruId !== null && formInputIsValid.basvuruId
          }
          input={{
            id: "basvuruId",
            style: { height: "35px" },
            placeholder: "Başvuru",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                basvuruId: Validation("Başvuru", value, { isRequired: true }),
              }));

              setFormInput((prev) => ({
                ...prev,
                basvuruId: value,
              }));
            },
          }}
        />
        <div style={{ display: "flex" }}>
          <Input
            type="input"
            style={{ color: "#1B437E", fontWeight: "600" }}
            label="T.C. Kimlik No"
            validation={
              formInput.tcKimlikNo != null && formInputIsValid.tcKimlikNo
            }
            input={{
              id: "tcKimlikNo",
              style: { height: "35px" },
              placeholder: "T.C. Kimlik No",
              onChange: (event) => {
                const value = event.target.value;
                setFormInputIsValid((prev) => ({
                  ...prev,
                  tcKimlikNo: Validation("T.C. Kimlik No", value, {
                    isRequired: true,
                    type: 1,
                    min: 11,
                    max: 11,
                  }),
                }));
                setFormInput((prev) => ({
                  ...prev,
                  tcKimlikNo: value,
                }));
              },
            }}
          />
          <Input
            type="input"
            label="Doğum Tarihi"
            style={{ color: "#1B437E", fontWeight: "600" }}
            validation={
              formInput.dogumTarihi != null && formInputIsValid.dogumTarihi
            }
            input={{
              id: "dogumTarihi",
              style: { height: "35px" },
              type: "date",
              placeholder: "Doğum Tarihi",
              value: formInput.dogumTarihi != null ? formInput.dogumTarihi : "",
              onChange: (event) => {
                const value = FixDate(event.target.value);
                setFormInputIsValid((prev) => ({
                  ...prev,
                  dogumTarihi: Validation("Doğum Tarihi", value, {
                    isRequired: true,
                    type: 3,
                  }),
                }));
                setFormInput((prev) => ({
                  ...prev,
                  dogumTarihi: value,
                }));
              },
            }}
          />
        </div>

        <Input
          type="phoneNumber"
          label="Telefon Numarası"
          style={{ width: "100%", marginTop: 0, paddingTop: 0 }}
          onlyCountries={["tr"]}
          validation={
            formInput.cepTelefonu != null && formInputIsValid.cepTelefonu
          }
          input={{
            id: "cepTelefonu",
            style: { padding: "0 4rem", height: "35px" },
            type: "text",
            onChange: (event) => {
              setFormInputIsValid((prev) => ({
                ...prev,
                cepTelefonu: Validation("Telefon Numarası", event, {
                  isRequired: true,
                  min: 12,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                cepTelefonu: event,
              }));
            },
          }}
        />
        <Input
          disableLabel={true}
          label={HTMLReactParser(
            `<span style="color:#9D9D9D;text-decoration:underline"><span style="color:#1c2f53;text-decoration:underline">KVKK Aydınlatma Metni</span>’ni okuduğumu onaylıyorum.</span>`,
          )}
          style={{ width: "100%" }}
          type="input"
          validation={formInput.kvkk != null && formInputIsValid.kvkk}
          checked={
            formInput != null && formInput.kvkk != null && formInput.kvkk
          }
          checkboxStyle={{
            justifyContent: "flex-start",
            border: "none",
            height: ".1rem",
            fontSize: ".8rem",
            marginTop: ".5rem",
          }}
          input={{
            id: "kvkk",
            type: "checkbox",

            onChange: (event) => {
              Swal.fire({
                width: 1000,
                html: KVKK(),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#118c11",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
              }).then(async (result) => {
                const element = document.getElementById(event.target.id);
                if (result.isConfirmed) {
                  element.checked = true;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    kvkk: {
                      status: true,
                      message: ``,
                    },
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    kvkk: true,
                  }));
                } else {
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    kvkk: {
                      status: false,
                      message: `KVKK: Onayı zorunludur.`,
                    },
                  }));
                  element.checked = false;
                  setFormInput((prev) => ({
                    ...prev,
                    kvkk: false,
                  }));
                }
              });
            },
          }}
        />
        <Input
          disableLabel={true}
          label={HTMLReactParser(
            `<span style="color:#9D9D9D;text-decoration:underline"><span style="color:#1c2f53;text-decoration:underline;font-size: 0.8rem">Rıza Metni</span>’ni okuduğumu onaylıyorum.</span>`,
          )}
          style={{ width: "100%" }}
          type="input"
          validation={formInput.rizaMetni != null && formInputIsValid.rizaMetni}
          checked={formInput.rizaMetni != null && formInput.rizaMetni}
          checkboxStyle={{
            justifyContent: "flex-start",
            border: "none",
            height: "1rem",
            fontSize: ".8rem",
          }}
          input={{
            id: "rizaMetni",
            type: "checkbox",
            onChange: (event) => {
              Swal.fire({
                width: 1000,
                html: RizaMetni(),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#118c11",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
              }).then(async (result) => {
                const element = document.getElementById(event.target.id);
                if (result.isConfirmed) {
                  element.checked = true;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    rizaMetni: {
                      status: true,
                      message: ``,
                    },
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    rizaMetni: true,
                  }));
                } else {
                  element.checked = false;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    rizaMetni: {
                      status: false,
                      message: `Rıza Metni: Onayı zorunludur.`,
                    },
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    rizaMetni: false,
                  }));
                }
              });
            },
          }}
        />
        <div className={classes.formButton}>
          <button>Başvuru Yap</button>
        </div>
      </form>
    </Fragment>
  );
};
