import React, { Fragment, useContext } from "react";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/app-context";

export const Footer = () => {
  const appContext = useContext(AppContext);
  return (
    <footer className={classes.footer}>
      <div className={classes.main}>
        <div className={classes.mainLeft}>
          <div className={classes.social}>
            <Link
              to="https://www.facebook.com/izmirbuyuksehirbel"
              target="_blank"
            >
              <img
                src="/assets/icons/ibbFooter/social/facebook.svg"
                alt="Facebook"
              />
            </Link>
            <Link
              to="https://www.instagram.com/izmirbuyuksehirbelediyesi/"
              target="_blank"
            >
              <img
                src="/assets/icons/ibbFooter/social/instagram.svg"
                alt="Instagram"
              />
            </Link>
            <Link
              to="https://www.linkedin.com/company/i%CC%87zmir-b%C3%BCy%C3%BCk%C5%9Fehir-belediyesi/"
              target="_blank"
            >
              <img
                src="/assets/icons/ibbFooter/social/linkedin.svg"
                alt="linkedin"
              />
            </Link>
            <Link to="https://twitter.com/izmirbld" target="_blank">
              <img src="/assets/icons/ibbFooter/social/twitter.svg" alt="x" />
            </Link>
            <Link
              to="https://www.bizizmir.com/tr/Him/BasvuruKayit"
              target="_blank"
              className={classes.contactUsBtn}
            >
              Bize Ulaşın
            </Link>
          </div>
          <div className={classes.container}>
            <Link to="tel:153" className={classes.item}>
              <img src="/assets/icons/ibbFooter/phone.png" alt="" />
              <p>
                Him Telefon: <span>153</span>
              </p>
            </Link>
            <Link to="fax:02322933995" className={classes.item}>
              <img src="/assets/icons/ibbFooter/fax.png" alt="" />
              <p>
                Faks: <span>0232 293 39 95</span>
              </p>
            </Link>
            <Link to="tel:02322931200" className={classes.item}>
              <img src="/assets/icons/ibbFooter/santral.png" alt="" />
              <p>
                Santral: <span>0232 293 12 00</span>
              </p>
            </Link>
          </div>
          <div className={classes.container}>
            <Link
              to="https://maps.app.goo.gl/fUu9mSXfSjFhA1xd9"
              target="_blank"
              className={classes.item}
            >
              <img src="/assets/icons/ibbFooter/map.png" alt="" />
              <p>
                Mimar Sinan Mahallesi 9 Eylül Meydanı No : 9/1 Kültürpark içi 1
                no’lu Hol Konak/İzmir
              </p>
            </Link>
            <Link to="mailto:him@izmir.bel.tr" className={classes.item}>
              <img src="/assets/icons/ibbFooter/email.png" alt="" />
              <p>
                E-Posta: <span>him@izmir.bel.tr</span>
              </p>
            </Link>
          </div>
        </div>
        <div className={classes.mainRight}>
          <div className={classes.ataturk}>
            <img src="/assets/img/ataturk.png" title="" alt="" />
          </div>

          <Link
            to="https://izmir.bel.tr"
            target="_blank"
            className={classes.ibbFooterLogo}
          >
            <img src="/assets/icons/ibbFooter/ibbFooterLogo.png" alt="" />
          </Link>
        </div>
      </div>

      <div className={classes.bottom}>
        <div className={classes.bottomLeft}>
          <p>&copy; {new Date().getFullYear()}</p>
          <p>Tüm Hakları İzmir Büyükşehir Belediyesi’ne aittir.</p>
        </div>
        <div className={classes.bottomRight}>
          {/*<Link>Çerez Politikamız</Link>*/}
          {/*<Link>Gizlilik ve Hizmet Politikamız</Link>*/}
          {/*<Link>Veri Politikamız</Link>*/}
        </div>
      </div>
    </footer>
  );
};
