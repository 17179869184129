import React, { Fragment, useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import "./mainSlider.css";
import { AppContext } from "../../../context/app-context";
import "swiper/css/pagination";
import "swiper/css";

const MainSlider = () => {
  const appContext = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetAnnouncementData();
  }, []);

  const GetAnnouncementData = async () => {
    const response = await appContext.FetchHandler(
      "Announcement",
      "get",
      null,
      null,
    );

    if (response.ok) {
      setData(response.data);
    }
  };
  return (
    <Fragment>
      {data.length > 0 && (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination]}
          className="mainSlider"
        >
          {data.map((x, i) => {
            return (
              <SwiperSlide key={x.id}>
                <Link
                  to={
                    x.id === "d77d71a0-7230-45ac-8d07-08dc83d62404"
                      ? "/destekver"
                      : `/duyuru?id=${x.id}`
                  }
                  state={{
                    data: data.filter((m) => m.id !== x.id),
                    selectedData: x,
                  }}
                >
                  <img src={x.imageUrl} alt="" style={{ objectFit: "cover" }} />

                  <div className="mainSliderContent">
                    {x.name != null && x.name.length > 0 && <h2>{x.name}</h2>}

                    {x.shortDescription != null &&
                      x.shortDescription.length > 0 && (
                        <p>{x.shortDescription}</p>
                      )}
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Fragment>
  );
};

export default MainSlider;
