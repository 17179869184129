import React, { Fragment, useContext } from "react";
import { RotatingLines } from "react-loader-spinner";
import { AppContext } from "../../../context/app-context";
import classes from "./Loader.module.css";

export const Loader = () => {
  // const appContext = useContext(AppContext);
  // let logo = "";

  // switch (appContext.lang) {
  //   case 0:
  //     logo = "logo-bg.png";
  //     break;
  //   case 1:
  //     logo = "logo-bg-en.png";
  //     break;
  //   default:
  //     break;
  // }
  return (
    <Fragment>
      <div className={classes.backdrop}></div>
      <div className={classes.loaderIcon}>
        <img
          src={`/assets/img/logo.png`}
          alt="Biz varız, yanınızdayız"
          width="240px"
        />
        <RotatingLines
          strokeColor="#fff"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    </Fragment>
  );
};
