import React, { Fragment, useContext, useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import classes from "./SidebarClient.module.css";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/app-context";

export const SidebarClient = (props) => {
  const appContext = useContext(AppContext);
  const [pathName, setPathName] = useState();
  const { toggleSidebar } = useProSidebar();

  const MenuHandler = (pathName) => {
    setPathName(pathName);
    toggleSidebar();
  };

  const SupportLinkHandler = () => {
    return appContext.supportData.map((x, i) => {
      if (x.supportType == 6) {
        return (
          <MenuItem key={i} component={<Link to={x.outUrl} target="_blank" />}>
            <div className={`menuItemContentBox ${classes.menuItemContentBox}`}>
              <span className={classes.menuItemContentText}>{x.name}</span>
            </div>
          </MenuItem>
        );
      } else {
        return <Fragment key={i}>{MenuItemHandler(x.url, x.name, x)}</Fragment>;
      }
    });
  };

  const MenuItemHandler = (url, text, data) => {
    return (
      <MenuItem
        active={pathName === url}
        component={<Link to={url} state={data} />}
        onClick={() => MenuHandler(url)}
      >
        <div className={`menuItemContentBox ${classes.menuItemContentBox}`}>
          <span className={classes.menuItemContentText}>{text}</span>
        </div>
      </MenuItem>
    );
  };

  return (
    <Fragment>
      <Sidebar
        defaultCollapsed={false}
        breakPoint="xxl"
        backgroundColor="#1b437e"
        rootStyles={{
          border: "none",
          backgroundColor: "#043657",
          boxShadow: "0 2px 15px rgba(0, 0, 0, .3)",
          height: "100vh",
        }}
      >
        <Link className={classes.logo} to="/" onClick={() => MenuHandler("/")}>
          <img
            src="/assets/img/logo.png"
            alt="logo"
            style={{ width: "150px" }}
          ></img>
        </Link>

        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0) {
                return {
                  color: disabled ? "#ccc" : "#fff",
                  "&:hover": {
                    backgroundColor: "#315a8f",
                  },
                  backgroundColor: active && "#315a8f",
                  fontWeight: "bold",
                  fontSize: ".9rem",
                };
              }
            },
          }}
          rootStyles={{ paddingBottom: "2rem" }}
        >
          {MenuItemHandler(
            "sutKuzusu",
            "SÜT KUZUSU",
            appContext.supportData &&
              appContext.supportData.filter(
                (x) => x.id === process.env.REACT_APP_SUT_KUZUSU_ID,
              )[0],
          )}
          {MenuItemHandler("/randevuHizmetleri", "RANDEVU HİZMETLERİ")}
          {MenuItemHandler("/destekVer", "DESTEK VER")}
          {MenuItemHandler("/basvuruSorgula", "BAŞVURU SORGULA")}
          {MenuItemHandler("/hizmetlerimiz", "HİZMETLERİMİZ")}
          <SubMenu
            label="İZMİR ŞEHİR KARTI"
            rootStyles={{
              ["& > ." + classes.button]: {
                backgroundColor: "blue",

                color: "fff",
                "&:hover": {
                  backgroundColor: "#11284c",
                },
              },
              ["." + classes.subMenuContent]: {
                backgroundColor: "#11284c",
              },
            }}
          >
            {MenuItemHandler(
              "https://servisler.bizimpos.com/izmir_uye_isyerleri",
              "Alışveriş Noktaları",
            )}
            {MenuItemHandler(
              "https://www.izmirteknoloji.com.tr/tr/uye-isyeri",
              "Üye İş Yeri Başvurusu",
            )}
          </SubMenu>

          {/*<SubMenu*/}
          {/*  label="SOSYAL DESTEKLER"*/}
          {/*  active={pathName === "/supports/"}*/}
          {/*  rootStyles={{*/}
          {/*    ["& > ." + classes.button]: {*/}
          {/*      backgroundColor: "blue",*/}
          {/*      color: "red",*/}
          {/*      "&:hover": {*/}
          {/*        backgroundColor: "#11284c",*/}
          {/*      },*/}
          {/*    },*/}
          {/*    ["." + classes.subMenuContent]: {*/}
          {/*      backgroundColor: "#11284c",*/}
          {/*    },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {appContext.supportData && SupportLinkHandler()}*/}
          {/*</SubMenu>*/}
          {MenuItemHandler("/sss", "SSS")}
          {MenuItemHandler("/iletisim", "İLETİŞİM")}
        </Menu>

        <div className={classes.ibbLogo}>
          <img src="/assets/img/brand/ibbHamburger.png" />
        </div>
      </Sidebar>
    </Fragment>
  );
};
