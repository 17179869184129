import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./GiveSupport.module.css";
import { AppContext } from "../../../context/app-context";
import { SupportBox } from "../../../components/client/supportBox/SupportBox";

export const GiveSupport = () => {
  const appContext = useContext(AppContext);
  const [supportData, setSupportData] = useState();

  useEffect(() => {
    if (appContext.supportData) {
      const tempData = Array.from(appContext.supportData).filter(
        (x) =>
          x.supportType == 2 ||
          x.supportType == 3 ||
          x.supportType == 4 ||
          x.supportType == 5,
      );
      // const kurban = appContext.supportData.filter(
      //   (x) => x.id === process.env.REACT_APP_KURBAN_ID,
      // )[0];
      //
      // if (kurban != undefined) {
      //   tempData.push(kurban);
      // }

      setSupportData(tempData);
    }
  }, [appContext.supportData]);

  return (
    <Fragment>
      <section className={classes.mainSection}>
        <div className={classes.headBox}>
          <div className={classes.headTitle}>
            <img src="/assets/icons/destekVer.png" alt="" />
            <h1>DESTEK VER</h1>
          </div>
          <p>
            Hayırseverlerin iyiliği, İzmir Büyükşehir Belediyesi'nin eliyle
            ihtiyaç sahiplerine ulaşıyor. Sosyal yardımlaşma ve dayanışma için
            ister bireysel ister kurumsal düzeyde ihtiyaç sahibi vatandaşlara
            ulaştırılmak üzere bağış yapabilirsiniz.
          </p>
        </div>
        <div className={classes.supportContainer}>
          {supportData &&
            supportData.map((x, i) => {
              return <SupportBox key={i} data={x} isGiveSupport={true} />;
            })}
        </div>
      </section>
    </Fragment>
  );
};
