import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./WebApplyForm.module.css";
import Swal from "sweetalert2";
import HTMLReactParser from "html-react-parser";
import { KVKK, RizaMetni } from "../../../util/commonText";
import { Validation } from "../../ui/input/Validation";
import { AppContext } from "../../../context/app-context";

export const WebApplyForm = ({
  formInput,
  setFormInput,
  formInputIsValid,
  setFormInputIsValid,
  submitHandler,
}) => {
  const appContext = useContext(AppContext);
  const [cityCodesData, setCityCodesData] = useState();
  const [districtCodesData, setDistrictCodesData] = useState();
  const [neighbourhoodCodes, setNeighbourhoodCodes] = useState();
  const [streetCodes, setStreetCodes] = useState();
  const [isAddresChangeAble, setIsAddresChangeAble] = useState(false);

  useEffect(() => {
    if (formInput.isAddressChangeAble) {
      DistrictHandler(35);
    }
  }, [formInput.isAddressChangeAble, appContext.gRecapReady]);

  const DistrictHandler = (cityId) => {
    if (cityId === "" || cityId == 0 || isNaN(cityId)) {
      setDistrictCodesData([]);
      setNeighbourhoodCodes([]);
      setStreetCodes([]);
      return;
    }
    setFormInput((prev) => ({
      ...prev,
      iletisimBilgisi: {
        ilce: "",
        ilceId: "",
        mahalle: "",
        mahalleId: "",
        yol: "",
        yolId: "",
      },
    }));
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/DistrictCodes",
        "post",
        "",
        { gToken: gToken, id: cityId },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        setDistrictCodesData(_tempData);
      }
    });
  };

  const NeighbourhoodHandler = (districtId) => {
    if (districtId === "" || districtId == 0 || isNaN(districtId)) {
      setNeighbourhoodCodes([]);
      setStreetCodes([]);

      return;
    }

    setFormInput((prev) => ({
      ...prev,
      iletisimBilgisi: {
        ...prev.iletisimBilgisi,
        mahalleId: 0,
        yolId: 0,
      },
    }));
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/NeighborhoodCodes",
        "post",
        "",
        { gToken: gToken, id: districtId },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        setNeighbourhoodCodes(_tempData);
      }
    });
  };

  const StreetHandler = (nbhId) => {
    if (nbhId === "" || nbhId == 0 || isNaN(nbhId)) {
      setStreetCodes([]);

      return;
    }
    setFormInput((prev) => ({
      ...prev,
      iletisimBilgisi: {
        ...prev.iletisimBilgisi,
        yolId: "",
      },
    }));
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/streetCodes",
        "post",
        "",
        { gToken: gToken, id: formInput.iletisimBilgisi.ilceId, nbhId: nbhId },
      );

      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        setStreetCodes(_tempData);
      }
    });
  };

  return (
    <Fragment>
      <form id="form" className={classes.form} onSubmit={submitHandler}>
        <Input
          type="input"
          style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
          label={`T.C. Kimlik No ${
            formInput.supportId.toLowerCase() ===
            "39d5aa4c-c546-4a5a-4a90-08dbd092062f"
              ? "(Ebeveyn T.C.)"
              : ""
          } `}
          validation={
            formInput.tcKimlikNo != null && formInputIsValid.tcKimlikNo
          }
          input={{
            id: "tcKimlikNo",
            placeholder: "T.C. Kimlik No",
            value: formInput.tcKimlikNo != null ? formInput.tcKimlikNo : "",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                tcKimlikNo: Validation("T.C. Kimlik No", value, {
                  isRequired: true,
                  type: 1,
                  min: 11,
                  max: 11,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                tcKimlikNo: value,
              }));
            },
          }}
        />
        <div className={classes.group}>
          <Input
            type="input"
            label="Doğum Tarihi"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            validation={
              formInput.dogumTarihi != null && formInputIsValid.dogumTarihi
            }
            input={{
              id: "dogumTarihi",
              type: "date",
              placeholder: "Doğum Tarihi",
              value: formInput.dogumTarihi != null ? formInput.dogumTarihi : "",
              onChange: (event) => {
                const value = event.target.value;
                setFormInputIsValid((prev) => ({
                  ...prev,
                  dogumTarihi: Validation("Doğum Tarihi", value, {
                    isRequired: true,
                    type: 3,
                  }),
                }));
                setFormInput((prev) => ({
                  ...prev,
                  dogumTarihi: value,
                }));
              },
            }}
          />
          <Input
            type="phoneNumber"
            label="Telefon Numarası"
            style={{ width: "100%" }}
            onlyCountries={["tr"]}
            validation={
              formInput.cepTelefonu != null && formInputIsValid.cepTelefonu
            }
            input={{
              id: "cepTelefonu",
              type: "text",
              value: formInput.cepTelefonu != null ? formInput.cepTelefonu : "",
              onChange: (event) => {
                setFormInputIsValid((prev) => ({
                  ...prev,
                  cepTelefonu: Validation("Telefon Numarası", event, {
                    isRequired: true,
                    min: 12,
                  }),
                }));
                setFormInput((prev) => ({
                  ...prev,
                  cepTelefonu: event,
                }));
              },
            }}
          />
        </div>

        {formInput.isAddressChangeAble && (
          <div>
            <Input
              disableLabel={true}
              label={
                formInput.supportId != null &&
                formInput.supportId == process.env.REACT_APP_SUT_KUZUSU_ID
                  ? HTMLReactParser(
                      `<span style="color:#1c2f53;text-decoration:underline">Sütlerin farklı bir adrese teslim edilmesini istiyorum.</span> <br/> <span style="color:#9D9D9D;">(iş yeri adreslerine teslimat yapılamamaktadır.)</span>`,
                    )
                  : HTMLReactParser(
                      `<span style="color:#9D9D9D;text-decoration:underline">Yardım yapılacak <span style="color:#1c2f53;text-decoration:underline">Teslimat</span> adresini değiştirmek istiyorum.</span>`,
                    )
              }
              style={{ width: "100%" }}
              type="input"
              checkboxStyle={{
                justifyContent: "flex-start",
                border: "none",
                height: "1rem",
                marginTop: "1rem",
              }}
              input={{
                id: "isAddressChange",
                type: "checkbox",
                onChange: () => {
                  setIsAddresChangeAble(!isAddresChangeAble);
                },
              }}
            />
          </div>
        )}
        {isAddresChangeAble && (
          <div id="isAddresChangeAble">
            <div className={classes.group}>
              <Input
                label="İlçe"
                type="select"
                style={{ width: "100%" }}
                option={districtCodesData}
                input={{
                  id: "ilceId",
                  required: "required",
                  value: formInput.iletisimBilgisi.ilceId,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    NeighbourhoodHandler(parseInt(event.target.value));
                    setFormInput((prev) => ({
                      ...prev,
                      iletisimBilgisi: {
                        ...prev.iletisimBilgisi,
                        ilce: event.target[index].text,
                        ilceId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
              <Input
                label="Mahalle"
                type="select"
                style={{ width: "100%" }}
                option={neighbourhoodCodes}
                input={{
                  id: "mahalleId",
                  required: "required",
                  value: formInput.iletisimBilgisi.mahalleId,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    StreetHandler(parseInt(event.target.value));
                    setFormInput((prev) => ({
                      ...prev,
                      iletisimBilgisi: {
                        ...prev.iletisimBilgisi,
                        mahalle: event.target[index].text,
                        mahalleId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
            </div>
            <div className={classes.group}>
              <Input
                label="Sokak"
                type="select"
                style={{ width: "100%" }}
                option={streetCodes}
                input={{
                  id: "yolId",
                  required: "required",
                  value: formInput.iletisimBilgisi.yolId,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    setFormInput((prev) => ({
                      ...prev,
                      iletisimBilgisi: {
                        ...prev.iletisimBilgisi,
                        yol: event.target[index].text,
                        yolId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
              <Input
                type="input"
                style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
                label="Bina No"
                input={{
                  id: "disKapiNo",
                  required: "required",
                  value: formInput.iletisimBilgisi.disKapiNo,
                  placeholder: "Bina No",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      iletisimBilgisi: {
                        ...prev.iletisimBilgisi,
                        disKapiNo: value,
                      },
                    }));
                  },
                }}
              />
              <Input
                type="input"
                style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
                label="Daire No"
                input={{
                  id: "icKapiNo",
                  required: "required",
                  value: formInput.iletisimBilgisi.icKapiNo,
                  placeholder: "Daire No",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      iletisimBilgisi: {
                        ...prev.iletisimBilgisi,
                        icKapiNo: value,
                      },
                    }));
                  },
                }}
              />
            </div>
          </div>
        )}

        <Input
          disableLabel={true}
          label={HTMLReactParser(
            `<span style="color:#9D9D9D"><span style="color:#1c2f53;text-decoration:underline">KVKK Aydınlatma Metni</span>’ni okuduğumu onaylıyorum.</span>`,
          )}
          style={{ width: "100%" }}
          type="input"
          validation={formInput.kvkk != null && formInputIsValid.kvkk}
          checked={
            formInput != null && formInput.kvkk != null && formInput.kvkk
          }
          checkboxStyle={{
            justifyContent: "flex-start",
            border: "none",
            height: "1rem",
            marginTop: "1rem",
          }}
          input={{
            id: "kvkk",
            type: "checkbox",
            onChange: (event) => {
              Swal.fire({
                width: 1000,
                html: KVKK(),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#118c11",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
              }).then(async (result) => {
                const element = document.getElementById(event.target.id);
                if (result.isConfirmed) {
                  element.checked = true;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    kvkk: {
                      status: true,
                      message: ``,
                    },
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    kvkk: true,
                  }));
                } else {
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    kvkk: {
                      status: false,
                      message: `KVKK: Onayı zorunludur.`,
                    },
                  }));
                  element.checked = false;
                  setFormInput((prev) => ({
                    ...prev,
                    kvkk: false,
                  }));
                }
              });
            },
          }}
        />
        <Input
          disableLabel={true}
          label={HTMLReactParser(
            `<span style="color:#9D9D9D;"><span style="color:#1c2f53;text-decoration:underline">Rıza Metni</span>’ni okuduğumu onaylıyorum.</span>`,
          )}
          style={{ width: "100%" }}
          type="input"
          validation={formInput.rizaMetni != null && formInputIsValid.rizaMetni}
          checked={formInput.rizaMetni != null && formInput.rizaMetni}
          checkboxStyle={{
            justifyContent: "flex-start",
            border: "none",
            height: "1rem",
          }}
          input={{
            id: "rizaMetni",
            type: "checkbox",
            onChange: (event) => {
              Swal.fire({
                width: 1000,
                html: RizaMetni(),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#118c11",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
              }).then(async (result) => {
                const element = document.getElementById(event.target.id);
                if (result.isConfirmed) {
                  element.checked = true;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    rizaMetni: {
                      status: true,
                      message: ``,
                    },
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    rizaMetni: true,
                  }));
                } else {
                  element.checked = false;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    rizaMetni: {
                      status: false,
                      message: `Rıza Metni: Onayı zorunludur.`,
                    },
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    rizaMetni: false,
                  }));
                }
              });
            },
          }}
        />

        <div className={classes.formButton}>
          <button>BAŞVUR</button>
        </div>
      </form>
    </Fragment>
  );
};
