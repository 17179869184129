import React, { Fragment, useContext, useEffect } from "react";
import { Loader } from "../../../components/ui/common/Loader";
import { AppContext } from "../../../context/app-context";
import { Header } from "../header/Header";
import { Main } from "../main/Main";
import classes from "./Layout.module.css";
import { Footer } from "../footer/Footer";
import { ProSidebarProvider } from "react-pro-sidebar";
import { SidebarClient } from "../sidebar/SidebarClient";

export const Layout = () => {
  const appContext = useContext(AppContext);

  return (
    <Fragment>
      <ProSidebarProvider>
        {appContext.isMobile && <SidebarClient />}

        <div className={classes.layout} id="layout">
          {appContext.loader && <Loader />}
          <Header />
          <Main />
        </div>
      </ProSidebarProvider>
    </Fragment>
  );
};
