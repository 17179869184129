import { Fragment } from "react";
import { Link } from "react-router-dom";

export const KVKK = () => {
  return `<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="kvkkModalTitleMuh">Kvkk Onaylama Metni</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="modal-close"> </span>
        </button>
    </div>
    <div class="modal-body">
        <p><b>DEĞERLİ HEMŞEHRİMİZ;</b></p>
<br>
<p><b>KİŞİSEL VERİLERİN KORUNMASI KANUNU İLE İLGİLİ OLARAK SİZLERİ BİLGİLENDİRMEK İSTERİZ.</b></p>

<p>6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) hükümleri gereği, kimliğinizi belirli veya belirlenebilir kılan her türlü bilginiz “Kişisel Veri” olarak aşağıdaki kapsamda, Veri Sorumlusu sıfatıyla İZMİR BÜYÜKŞEHİR BELEDİYESİ BAŞKANLIĞI tarafından işlenecektir. “Kişisel Verilerinizin İşlenmesi” ise bu verilerin elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi ifade etmektedir.</p>

<p>İZMİR BÜYÜKŞEHİR BELEDİYESİ olarak kişisel verilerinizin güvenliğine en üst düzeyde önem vererek, sizlere sunduğumuz hizmetlerimizde kişisel verilerinizin güvenliğinin ön planda olduğu bilinciyle faaliyetlerimize devam ettiğimizi belirtmek isteriz.</p>

<p>Kişisel verilerinizin hizmetlerimizde kullanılması sırasında özel hayatınızın gizliliği ve temel hak ve özgürlüklerin korunması temel prensibimizdir.</p>
<br>
<p>Kişisel Verilerinizin İşlenme Amaçları ve Hukuki Sebepleri:</p>

<p>Kurumumuzun bağlı bulunduğu kanun ve ilgili mevzuatlar kapsamında sizlere sunulacak hizmetlerde kullanılmak, belediyecilik hizmetlerimizi gerçekleştirmek, bu kapsamda her türlü iş ve işlemin sahibini ve muhatabını belirlemek üzere bilgilerini tespit için, KVKK kapsamında kişisel veri olarak nitelendirilen kimlik bilgileriniz, adresiniz, telefon numaranız, vergi numaranız ve diğer bilgilerinizi kaydetmek, kağıt üzerinde ya da elektronik ortamda gerçekleştirilecek iş ve işlemlere dayanak olacak bilgi ve belgeleri düzenlemek, ilgili mevzuat uyarınca adli ve idari tüm yetkili makamlara öngörülen bilgi saklama, raporlama ve bilgilendirme yükümlülüklerine uymak, Kurumumuzca sunulan veya talep edilen başka hizmetlerimizi sunabilmektir.</p>
<br>
<p>Ayrıca Kanunun “Kişisel verilerin işlenme şartları” başlığı altında, ilgili kişinin açık rızası aranmaksızın kişisel verilerinin işlenmesini mümkün kılan şartlar şöyle sıralanmıştır;</p>
<p>a) Kanunlarda açıkça öngörülmesi. 
</p><p>b) Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması. </p>
<p>c) Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.</p>
<p>ç) Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması. </p>
<p>d) İlgili kişinin kendisi tarafından alenileştirilmiş olması. </p>
<p>e) Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması. </p>
<p>f) İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</p>
<br>
<p>Kişisel Verilerinizin Aktarılması:</p>

<p>Kurumumuz nezdinde bulunan kişisel verileriniz bağlı bulunduğumuz kanun, sair kanun ve sair mevzuat hükümlerinin zorunlu kıldığı/izin verdiği kişi, kurum ve/veya kuruluşlarla, vatandaşlara sunulmakta olan hizmetlerin yürütülmesi amacıyla ilçe belediyelerle, bağlı bulunan kanunlar kapsamında veya İzmir Büyükşehir Meclisinden onay alınmak suretiyle üniversiteler, kamu kurumları, sivil toplum kuruluşlarıyla ve belediyecilik faaliyetlerimizi yürütmek üzere hizmet alınan üçüncü taraflara yasal sınırlamalar çerçevesinde ve Gizlilik Sözleşmesi ile aktarılabilecektir.</p>
<br>
<p>Kişisel Verilerin Toplanma Yöntemi:</p>

<p>Kişisel verileriniz Belediyenin bağlı olduğu kanunlar ve diğer ilgili mevzuatlar uyarınca onay ve/veya imzanızla tanzim edilen belediyecilik işlemlerine ilişkin tüm beyanname/bilgilendirme formları ve sair belgelerle, elektronik onay ve/veya imzanız ile yapacağınız bildirimlerle, Kurumumuz, yerel hizmet birimleri, Web Sayfaları, Mobil Uygulamalar, Çağrı Merkezi, Kiosklar gibi kanallar aracılığı ile sözlü, yazılı veya elektronik ortamda olmak kaydıyla çeşitli yöntemlerle toplanmaktadır.</p>
<br>
<p>Kanun’un 11. Maddesi uyarınca haklarınız:</p>

<p>Kurumumuza başvurmak suretiyle kişisel verilerinizin;</p>

<p>a) İşlenip işlenmediğini öğrenme,</p>
<p>b) İşlenmişse bilgi talep etme,</p>
<p>c) İşlenme amacını ve amacına uygun kullanıp kullanılmadığını öğrenme,</p>
<p>ç) Yurt içinde/yurt dışında aktarıldığı 3. Kişileri bilme,</p>
<p>d) Eksik/yanlış işlenmişse düzeltilmesini isteme,</p>
<p>e) KVKK’nın 7. Maddesinde öngörülen şartlar çerçevesinde silinmesini/yok edilmesini isteme,</p>
<p>f) (d) ve (e) maddeleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
<p>g) Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</p>
<p>ğ) Kanuna aykırı işlenmesi sebebiyle zarara uğramanız halinde zararınızın giderilmesini talep etme hakkına sahipsiniz.</p>

<p>KVKK’nın 13. maddesinin 1. fıkrası gereğince, yukarıda belirtilen haklarınızı kullanmak ile ilgili talebinizi, yazılı veya KEP üzerinden elektronik imzalı olarak Kurumumuza iletebilirsiniz. Bu çerçevede Kurumumuza KVKK’nın 11. maddesi kapsamında yapacağınız başvurularda yazılı olarak başvurunuzu ileteceğiniz kanallar ve usuller aşağıda açıklanmaktadır. </p>

<p>Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler ile açıklamalarınızı içeren talebinizi; www.izmir.bel.tr adresindeki formu doldurarak, formun imzalı bir nüshasını İzmir Büyükşehir Belediyesi Yazı İşleri Şube Müdürlüğü Mimar Sinan Mahallesi 9 Eylül Meydanı No:9/1 Kültürpark içi 1 no’lu Hol Konak PK:35250 İzmir adresine kimliğinizi tespit edici belgeler ile bizzat elden iletebilir, noter kanalıyla gönderebilir veya ilgili formu izmirbuyuksehirbelediye@hs01.kep.tr adresine güvenli elektronik imzalı olarak iletebilirsiniz.</p>
<br>

<strong><a href="https://www.izmir.bel.tr/CKYuklenen/KVKK/KVKKBasvuruFormu09012022.docx" target="_blank">Kişisel Veri Başvuru Formu</a></strong>
    </div>

</div>`;
};

export const RizaMetni = () => {
  return `<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="kvkkModalTitle">Riza Onaylama Metni</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="modal-close"> </span>
        </button>
    </div>
    <div class="modal-body">
        <p> İzmir Büyükşehir Belediyesi Sosyal Hizmetler Dairesi Başkanlığına bağlı Sosyal Yardımlar Şube Müdürlüğünce ihtiyaç sahibi ailelere yapılan  yardımdan faydalanmak istiyorum.</p>

        <p>   Tarafımdan istenilen bilgileri kendim ve aile bireylerini kapsayacak şekilde doğru olarak beyan ettiğimi, yukarıda belirtmiş olduğum bilgilerde değişiklik olması halinde tarafınıza bildireceğimi; ayrıca  6698 sayılı kişisel verilerin korunması kanunu dahilinde kişisel verilerimin sorgulanmasında, dijital ortamda saklanmasında sakınca bulunmadığını, bu hususta hakkımda ve aile bireylerim adına her türlü tahkikatın  yapılmasını  kabul ettiğimi ve doğacak her türlü sorumluluğun tarafıma ait olduğunu taahhüt ederim.</p>
    </div>
</div>`;
};

export const MesafeliSatisSizkesnesi = () => {
  return `<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="kvkkModalTitle">Mesafeli Satış Sözleşmesi</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="modal-close"> </span>
        </button>
    </div>
    <div class="modal-body">
        <p> İzmir Büyükşehir Belediyesi Sosyal Hizmetler Dairesi Başkanlığına bağlı Sosyal Yardımlar Şube Müdürlüğünce ihtiyaç sahibi ailelere yapılan  yardımdan faydalanmak istiyorum.</p>

        <p>   Tarafımdan istenilen bilgileri kendim ve aile bireylerini kapsayacak şekilde doğru olarak beyan ettiğimi, yukarıda belirtmiş olduğum bilgilerde değişiklik olması halinde tarafınıza bildireceğimi; ayrıca  6698 sayılı kişisel verilerin korunması kanunu dahilinde kişisel verilerimin sorgulanmasında, dijital ortamda saklanmasında sakınca bulunmadığını, bu hususta hakkımda ve aile bireylerim adına her türlü tahkikatın  yapılmasını  kabul ettiğimi ve doğacak her türlü sorumluluğun tarafıma ait olduğunu taahhüt ederim.</p>
    </div>
</div>`;
};

export const AcikRizaMetniKurban = () => {
  return `<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="kvkkModalTitle">Açık Rıza Metni</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="modal-close"> </span>
        </button>
    </div>
    <div class="modal-body">
        <p> İzmir Büyükşehir Belediyesi Kişisel Verilerin Korunması ve İşlenmesi Aydınlatma Metni’ni okudum. <p>
<p>Kişisel verilerimin aydınlatma metninde belirtilen amaçlar için işlenmesine ve paylaşılmasına ayrıca; İzmir Büyükşehir Belediyesi Kurban bayramı kapsamında kurban keserek engelli, yoksul, yaşlı ve her türlü ihtiyaç sahibi İzmirlilere yıl boyunca kurban eti dağıtmak amacıyla yapmış olduğu yardım kampanyasına katılmam sebebi ile paylaştığım her türlü kişisel verimin işlenmesine, ilgili iş ortakları ile paylaşılmasına, adımın ve soyadımın tüm bu kişisel verilerimin İzmir Büyükşehir Belediyesinin faaliyetlerinin planlanması ve icrası amaçları da dahil olmak üzere faaliyetlerinin gerçekleştirilmesi amacıyla; toplanmasına, işlenmesine, veri tabanında tutularak periyodik olarak güncellenmesine ve saklanmasına, gerektiği takdirde ilgili kamu kurum ve kuruluşlarıyla, 3. kişilerle, İzmir Büyükşehir Belediyesinin İştirakleri ve Bağlı kuruluşlarıyla, faaliyetlerinin yürütülmesi için Türkiye’de veya yurtdışında mukim olan; hizmet aldığı, birlikte çalıştığı kuruluşlarla, hizmet sağlayıcı firmalar ile paylaşılmasına muvafakat ediyorum. Vekâleten kurban bağışına ilişkin olarak vekâletimi İzmir Büyükşehir Belediyesi Sosyal Hizmetler Dairesi Başkanlığına veriyorum.
</p>
    </div>
</div>`;
};
