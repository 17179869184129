import React, { useEffect } from "react";
import classes from "./Iletisim.module.css";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Swal from "sweetalert2";
import { ScrollTop } from "../../../util/commonUtil";

export const Iletisim = () => {
  useEffect(() => {
    if (!window.google) {
      AppendGMap();
    } else {
      initMap();
    }
  }, []);

  const AppendGMap = () => {
    const gMap = document.createElement("script");
    gMap.id = "gMap";
    gMap.defer = true;
    gMap.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDPfthBHpkDtsvqgbEtvRHdVRfaPOahYqA&callback=initMap&v=weekly`;
    document.body.appendChild(gMap);
    gMap.addEventListener("load", () => {
      window.initMap = initMap;
    });
  };

  function initMap() {
    const google = window.google;
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 11,
      center: { lat: 38.454633, lng: 27.168911 },
    });
    // Set LatLng and title text for the markers. The first marker (Boynton Pass)
    // receives the initial focus when tab is pressed. Use arrow keys to
    // move between markers; press tab again to cycle through the map controls.
    const markers = [
      [
        { lat: 38.474941, lng: 27.136173 },
        MarkerDetail(
          "NAFİZ GÜRMAN NOKTASI",
          "KUBİLAY CADDESİ NO:61 BAYRAKLI / İZMiR",
          "https://maps.app.goo.gl/jairhFjsUnHQk2339",
        ),
        "NAFİZ GÜRMAN NOKTASI",
      ],
      [
        { lat: 38.471939, lng: 27.167121 },
        MarkerDetail(
          "ALPASLAN NOKTASI",
          "1620/15 SOK NO:29-2/A BAYRAKLI / İZMİR",
          "https://maps.app.goo.gl/wXrEdbyxeyxnaMrK8",
        ),
        "ALPASLAN NOKTASI",
      ],
      [
        { lat: 38.36413, lng: 27.148015 },
        MarkerDetail(
          "GÖKSU NOKTASI",
          "684/11 SOKAK NO:38/A BUCA / İZMİR",
          "https://maps.app.goo.gl/cQSXLZaUCEkUL33a9",
        ),
        "GÖKSU NOKTASI",
      ],
      [
        { lat: 38.53059853013401, lng: 27.048960225898494 },
        MarkerDetail(
          "HARMANDALI NOKTASI",
          "8790/8 Sokak No:1/B Çiğli / İzmir",
          "https://maps.app.goo.gl/ULXLm16p61qKKGsA7",
        ),
        "HARMANDALI NOKTASI",
      ],
      [
        { lat: 38.53059853013401, lng: 27.048960225898494 },
        MarkerDetail(
          "ÖRNEKKÖY NOKTASI",
          "Zübeyde Hanım Mah. Başpehlivan Karaali cd. No:377 Karşıyaka / İzmir",
          "https://maps.app.goo.gl/V1C2YMKXbNvax4hc6",
        ),
        "ÖRNEKKÖY NOKTASI",
      ],
      [
        { lat: 38.378527, lng: 27.100844 },
        MarkerDetail(
          "LİMONTEPE NOKTASI",
          "9677 SOK NO:7 KARABAĞLAR / İZMİR",
          "https://maps.app.goo.gl/TWHmVHc7m5TpZzmM9",
        ),
        "LİMONTEPE NOKTASI",
      ],
      [
        { lat: 38.41011, lng: 27.172745 },
        MarkerDetail(
          "ULUBATLI NOKTASI",
          "2739 SOK NO: 36/A KONAK / İZMİR",
          "https://maps.app.goo.gl/dSMMZM7G8tq55m3e7",
        ),
        "ULUBATLI NOKTASI",
      ],
      [
        { lat: 38.416295, lng: 27.139685 },
        MarkerDetail(
          "SÜVARI NOKTASI",
          "803 SOK NO:87 KONAK / İZMİR",
          "https://maps.app.goo.gl/X2yTRV3PDZZKNj2K8",
        ),
        "SÜVARI NOKTASI",
      ],
      [
        { lat: 38.42286362281238, lng: 27.139685 },
        MarkerDetail(
          "GÜNEY NOKTASI",
          "1149 SOK NO:24/A KONAK / İZMİR",
          "https://maps.app.goo.gl/CiEPC2pqpo4fzkD6A",
        ),
        "GÜNEY NOKTASI",
      ],
      [
        { lat: 38.40929284048491, lng: 27.135592552525313 },
        MarkerDetail(
          "1. KADRİYE NOKTASI",
          "628 SOK NO:27 KONAK / İZMİR",
          "https://maps.app.goo.gl/UfJsqp9gXEamKKCk6",
        ),
        "1. KADRİYE NOKTASI",
      ],
      [
        { lat: 38.41512764217976, lng: 27.153370559359185 },
        MarkerDetail(
          "BALLIKUYU NOKTASI",
          "BALLIKUYU CADDESİ NO:178/A KONAK / İZMİR",
          "https://maps.app.goo.gl/gHchikK6qKJUzrWA8",
        ),
        "BALLIKUYU NOKTASI",
      ],
      [
        { lat: 38.410942526410565, lng: 27.16536858044165 },
        MarkerDetail(
          "FERAHLI NOKTASI",
          "3514 SOK NO:40 KONAK / İZMİR",
          "https://maps.app.goo.gl/Y4jwaAqKDhKkk37KA",
        ),
        "FERAHLI NOKTASI",
      ],
    ];
    // Create an info window to share between markers.
    const infoWindow = new google.maps.InfoWindow();

    // Create the markers.
    markers.forEach(([position, text, name], i) => {
      const marker = new google.maps.Marker({
        position,
        map,
        title: name,
        text: `${text}`,
        // label: `${i + 1}`,
        label: ``,
        optimized: false,
      });

      // Add a click listener for each marker, and set up the info window.
      marker.addListener("click", () => {
        infoWindow.close();
        infoWindow.setContent(marker.text);
        infoWindow.open(marker.getMap(), marker);
      });

      const markerUl = document.getElementById("markerUl");
      const li = document.createElement("li");
      li.addEventListener("click", () => {
        infoWindow.close();
        infoWindow.setContent(marker.text);
        infoWindow.open(marker.getMap(), marker);
      });
      li.innerHTML = `${name}`;
      markerUl.append(li);

      const select = document.getElementById("markerSelect");
      const option = document.createElement("option");
      option.innerText = name;
      option.value = i.toString();

      select.append(option);
      select.addEventListener("change", (event) => {
        if (event.target.value === i.toString()) {
          infoWindow.close();
          infoWindow.setContent(marker.text);
          infoWindow.open(marker.getMap(), marker);
        }
      });
    });
  }
  window.initMap = initMap;
  const MarkerDetail = (name, address, location) => {
    return `    
    <div style="display: flex; flex-direction: column;justify-content: center;align-items: flex-start;">
        <h3 style="margin: .5rem 0;">${name}</h3>
        ${address}
        <a style=" background-color: #1b437e; color: #fff; padding: .5rem 1rem;margin:.5rem auto; border-radius: 10px;cursor: pointer;"
        href="${location}" target="_blank">
          Yol Tarifi Al
        </a>
    </div>
`;
  };

  return (
    <Fragment>
      <section className={classes.section}>
        <div className={classes.contactBox}>
          <h1>İletişim</h1>
          <p>
            Sorularınız için <Link to="/sss">SSS (Sıkça Sorulan Sorular) </Link>
            başlığımızdan bilgi alabilir ya da <Link to="tel:153">Alo 153</Link>
            ’ü arayabilirsiniz.
          </p>
          <img src="/assets/img/him.png" alt="icon" />
          <Link
            to="https://him.izmir.bel.tr"
            target="_blank"
            className={classes.btnHim}
          >
            HİM WEB SİTESİ
          </Link>
        </div>
        <div className={classes.socialBox}>
          <button
            title="Facebook"
            onClick={() => {
              Swal.fire({
                text: "Sosyal Hizmetler Dairesi Başkanlığı'nın Facebook adresine mi, yoksa İzmir Büyükşehir Belediyesi'nin Facebook adresine mi gitmek istersiniz?",
                showCancelButton: true,
                confirmButtonColor: "#004684",
                confirmButtonText: "İzmir Büyükşehir Belediyesi",
                cancelButtonColor: "#0467be",
                cancelButtonText: "Sosyal Hizmetler Dairesi Başkanlığı",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  window.open(
                    "https://www.facebook.com/izmirbuyuksehirbel",
                    "_blank",
                  );
                } else if (
                  result.isDismissed &&
                  result.dismiss.toString() === "cancel"
                ) {
                  window.open(
                    "https://www.facebook.com/izbbsosyalhizmetler",
                    "_blank",
                  );
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faFacebook} className={classes.socialIcon} />
          </button>
          <button
            title="X"
            onClick={() => {
              Swal.fire({
                text: "Sosyal Hizmetler Dairesi Başkanlığı'nın X adresine mi, yoksa İzmir Büyükşehir Belediyesi'nin X adresine mi gitmek istersiniz?",
                showCancelButton: true,
                confirmButtonColor: "#004684",
                confirmButtonText: "İzmir Büyükşehir Belediyesi",
                cancelButtonColor: "#0467be",
                cancelButtonText: "Sosyal Hizmetler Dairesi Başkanlığı",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  window.open("https://twitter.com/izmirbld", "_blank");
                } else if (
                  result.isDismissed &&
                  result.dismiss.toString() === "cancel"
                ) {
                  window.open("https://twitter.com/izbbshdb", "_blank");
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faXTwitter} className={classes.socialIcon} />
          </button>
          <button
            title="Instagram"
            onClick={() => {
              Swal.fire({
                text: "Sosyal Hizmetler Dairesi Başkanlığı'nın Instagram adresine mi, yoksa İzmir Büyükşehir Belediyesi'nin Instagram adresine mi gitmek istersiniz?",
                showCancelButton: true,
                confirmButtonColor: "#004684",
                confirmButtonText: "İzmir Büyükşehir Belediyesi",
                cancelButtonColor: "#0467be",
                cancelButtonText: "Sosyal Hizmetler Dairesi Başkanlığı",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  window.open(
                    "https://www.instagram.com/izmirbuyuksehirbelediyesi/",
                    "_blank",
                  );
                } else if (
                  result.isDismissed &&
                  result.dismiss.toString() === "cancel"
                ) {
                  window.open(
                    "https://www.instagram.com/izbbsosyalhizmetler/",
                    "_blank",
                  );
                }
              });
            }}
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className={classes.socialIcon}
            />
          </button>
          <button
            title="Linkedin"
            onClick={() => {
              Swal.fire({
                text: "Sosyal Hizmetler Dairesi Başkanlığı'nın Linkedin adresine mi, yoksa İzmir Büyükşehir Belediyesi'nin Linkedin adresine mi gitmek istersiniz?",
                showCancelButton: true,
                confirmButtonColor: "#004684",
                confirmButtonText: "İzmir Büyükşehir Belediyesi",
                cancelButtonColor: "#0467be",
                cancelButtonText: "Sosyal Hizmetler Dairesi Başkanlığı",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  window.open(
                    "https://tr.linkedin.com/company/i̇zmir-büyükşehir-belediyesi",
                    "_blank",
                  );
                } else if (
                  result.isDismissed &&
                  result.dismiss.toString() === "cancel"
                ) {
                  window.open(
                    "https://www.linkedin.com/company/i̇zbb-sosyal-hizmetler-dairesi-başkanlığı/",
                    "_blank",
                  );
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faLinkedin} className={classes.socialIcon} />
          </button>
        </div>
        <div className={classes.supportPoint}>
          <h1>Dayanışma Noktaları</h1>
          <div className={classes.markerSelectBox}>
            <select id="markerSelect">
              <option>Seçim Yapınız</option>
            </select>
          </div>
          <div id="map" className={classes.map}></div>
          <div className={classes.markerList}>
            <ul id="markerUl"></ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
